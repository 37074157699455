<template>
    <div class="minigame-wrapper">
        <div class="iframe-wrapper">
            <iframe src="https://www.surepowerball.com/?id=two" scrolling="no" class="iiiiii" autoplay="1" allow="autoplay"></iframe>
        </div>
        <div class="minigame-menu">
            <div class="header">
                <div class="h-item">
                    <div class="title">회차</div>
                    <div class="value">360</div>
                </div>
                <div class="h-item">
                    <div class="title">마감</div>
                    <div class="value">00:18</div>
                </div>
            </div>
            <div class="betting-type">
                <button :class="{'active': isFirstTab}" @click="changeTab(true)">일반배팅</button>
                <button :class="{'active': !isFirstTab}" @click="changeTab(false)">조합배팅</button>
            </div>
            <div v-if="isFirstTab">
                <div class="betting-options">
                    <div class="option-grp my-col-5">
                        <div class="option-name">파워볼</div>
                        <div class="my-col my-col-4">
                            <div class="option disabled">
                                <div class="sides">1.95</div>
                                <div class="ball blue">홀</div>
                                <div class="sides bottom"></div>
                            </div>
                            <div class="option">
                                <div class="sides">1.95</div>
                                <div class="ball red">짝</div>
                                <div class="sides bottom"></div>
                            </div>
                            <div class="option">
                                <div class="sides">1.95</div>
                                <div class="ball blue-outline">언더</div>
                                <div class="sides bottom">4.5</div>
                            </div>
                            <div class="option">
                                <div class="sides">1.95</div>
                                <div class="ball red-outline">오버</div>
                                <div class="sides bottom">4.5</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="betting-options">
                    <div class="option-grp my-col-5">
                        <div class="option-name">일반볼</div>
                        <div class="my-col my-col-4">
                            <div class="option">
                                <div class="sides">1.95</div>
                                <div class="ball blue">홀</div>
                                <div class="sides bottom"></div>
                            </div>
                            <div class="option">
                                <div class="sides">1.95</div>
                                <div class="ball red">짝</div>
                                <div class="sides bottom"></div>
                            </div>
                            <div class="option">
                                <div class="sides">1.95</div>
                                <div class="ball blue-outline">언더</div>
                                <div class="sides bottom">72.5</div>
                            </div>
                            <div class="option">
                                <div class="sides">1.95</div>
                                <div class="ball red-outline">오버</div>
                                <div class="sides bottom">72.5</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="betting-options">
                    <div class="option-grp my-col-4">
                        <div class="option-name">일반볼구간</div>
                        <div class="my-col my-col-3">
                            <div class="option">
                                <div class="sides">2.8</div>
                                <div class="ball red">대</div>
                                <div class="sides bottom">81-130</div>
                            </div>
                            <div class="option">
                                <div class="sides">2.50</div>
                                <div class="ball blue">중</div>
                                <div class="sides bottom">65-80</div>
                            </div>
                            <div class="option">
                                <div class="sides">2.8</div>
                                <div class="ball green">소</div>
                                <div class="sides bottom">16-64</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="betting-options">
                    <div class="option-grp">
                        <div class="option-name">파워볼숫자</div>
                        <div class="my-col my-col-5">
                            <div class="option">
                                <div class="sides">9.00</div>
                                <div class="ball black">1</div>  
                                <div class="sides bottom"></div>
                            </div>
                            <div class="option">
                                <div class="sides">9.00</div>
                                <div class="ball black">2</div>
                                <div class="sides bottom"></div>
                            </div>
                            <div class="option">
                                <div class="sides">9.00</div>
                                <div class="ball black">3</div>
                                <div class="sides bottom"></div>
                            </div>
                            <div class="option">
                                <div class="sides">9.00</div>
                                <div class="ball black">4</div>
                                <div class="sides bottom"></div>
                            </div>
                            <div class="option">
                                <div class="sides">9.00</div>
                                <div class="ball black">5</div>
                                <div class="sides bottom"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="betting-options">
                    <div class="option-grp">
                        <div class="option-name">파워볼숫자</div>
                        <div class="my-col my-col-5">
                            <div class="option">
                                <div class="sides">9.00</div>
                                <div class="ball black">6</div>  
                                <div class="sides bottom"></div>
                            </div>
                            <div class="option">
                                <div class="sides">9.00</div>
                                <div class="ball black">7</div>
                                <div class="sides bottom"></div>
                            </div>
                            <div class="option">
                                <div class="sides">9.00</div>
                                <div class="ball black">8</div>
                                <div class="sides bottom"></div>
                            </div>
                            <div class="option">
                                <div class="sides">9.00</div>
                                <div class="ball black">9</div>
                                <div class="sides bottom"></div>
                            </div>
                            <div class="option">
                                <div class="sides">9.00</div>
                                <div class="ball black">0</div>
                                <div class="sides bottom"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="betting-options two-balls">
                    <div class="option-grp my-col-5 two-balls">
                        <div class="option-name">파워볼조합</div>
                        <div class="my-col my-col-4">
                            <div class="option">
                                <div class="sides">3.30</div>
                                <div class="b-section">
                                    <div class="ball blue">홀</div>
                                    <div class="ball red-outline">오버</div>
                                </div>
                            </div>
                            <div class="option">
                                <div class="sides">3.30</div>
                                <div class="b-section">
                                    <div class="ball blue">홀</div>
                                    <div class="ball blue-outline">언더</div>
                                </div>
                            </div>
                            <div class="option">
                                <div class="sides">3.30</div>
                                <div class="b-section">
                                    <div class="ball red">짝</div>
                                    <div class="ball red-outline">오버</div>
                                </div>
                            </div>
                            <div class="option">
                                <div class="sides">3.30</div>
                                <div class="b-section">
                                    <div class="ball red">짝</div>
                                    <div class="ball blue-outline">언더</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="option-grp my-col-5 two-balls">
                        <div class="option-name">일반볼조합</div>
                        <div class="my-col my-col-4">
                            <div class="option">
                                <div class="sides">3.50</div>
                                <div class="b-section">
                                    <div class="ball blue">홀</div>
                                    <div class="ball red-outline">오버</div>
                                </div>
                            </div>
                            <div class="option">
                                <div class="sides">3.30</div>
                                <div class="b-section">
                                    <div class="ball blue">홀</div>
                                    <div class="ball blue-outline">언더</div>
                                </div>
                            </div>
                            <div class="option">
                                <div class="sides">3.30</div>
                                <div class="b-section">
                                    <div class="ball red">짝</div>
                                    <div class="ball red-outline">오버</div>
                                </div>
                            </div>
                            <div class="option">
                                <div class="sides">3.30</div>
                                <div class="b-section">
                                    <div class="ball red">짝</div>
                                    <div class="ball blue-outline">언더</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="option-grp my-col-5 two-balls">
                        <div class="option-name">파워일반조합</div>
                        <div class="my-col my-col-4">
                            <div class="option">
                                <div class="sides">3.30</div>
                                <div class="b-section">
                                    <div class="ball blue">홀</div>
                                    <div class="ball blue">홀</div>
                                </div>
                            </div>
                            <div class="option">
                                <div class="sides">3.30</div>
                                <div class="b-section">
                                    <div class="ball blue">홀</div>
                                    <div class="ball red">짝</div>
                                </div>
                            </div>
                            <div class="option">
                                <div class="sides">3.30</div>
                                <div class="b-section">
                                    <div class="ball red">짝</div>
                                    <div class="ball blue">홀</div>
                                </div>
                            </div>
                            <div class="option">
                                <div class="sides">3.30</div>
                                <div class="b-section">
                                    <div class="ball red">짝</div>
                                    <div class="ball blue">짝</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="option-grp my-col-5 two-balls">
                        <div class="option-name">일반볼조합</div>
                        <div class="my-col my-col-3">
                            <div class="option">
                                <div class="sides">3.70</div>
                                <div class="b-section">
                                    <div class="ball blue">홀</div>
                                    <div class="ball red">대</div>
                                </div>
                            </div>
                            <div class="option">
                                <div class="sides">3.50</div>
                                <div class="b-section">
                                    <div class="ball blue">홀</div>
                                    <div class="ball blue">중</div>
                                </div>
                            </div>
                            <div class="option">
                                <div class="sides">3.70</div>
                                <div class="b-section">
                                    <div class="ball blue">홀</div>
                                    <div class="ball green">소</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="option-grp my-col-5 two-balls">
                        <div class="option-name">일반볼조합</div>
                        <div class="my-col my-col-3">
                            <div class="option">
                                <div class="sides">3.70</div>
                                <div class="b-section">
                                    <div class="ball red">짝</div>
                                    <div class="ball red">대</div>
                                </div>
                            </div>
                            <div class="option">
                                <div class="sides">3.50</div>
                                <div class="b-section">
                                    <div class="ball red">짝</div>
                                    <div class="ball blue">중</div>
                                </div>
                            </div>
                            <div class="option">
                                <div class="sides">3.70</div>
                                <div class="b-section">
                                    <div class="ball green">짝</div>
                                    <div class="ball green">소</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bet-history">
        <div class="header">최근배팅내역</div>
        <div class="body">
            <span>최근 배팅내역이 없습니다.</span>
        </div>
    </div>
</template>
<script>
export default {
    name:'Mini_Game',
    data(){
        return{
            isFirstTab:true
        }
    },
    methods:{
        changeTab(data){
            this.isFirstTab = data
        }
    }
}
</script>